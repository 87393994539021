body{
    background: black !important;
    overflow-x: hidden;
}

hr{
    background: gray !important;
}

a{
    text-decoration: none !important;
}

#nav{
    border-bottom: rgb(187, 134, 0) 2px solid;
}

.active{
    color: white !important;
}

.nav-links{
    color: rgb(187, 134, 0);
    font-weight: bold;
    transition: color ease 0.5s;
    margin-right: 2rem;
    text-decoration: none;
}

.nav-links:last-child{
    margin-right: 5em;
}

.nav-links:hover{
    color: white;
}

.branding{
    position: relative;
    min-height: 75vh;
}

.branding::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: url("./images/banner.png");
    background-size: cover;
    background-position: center;
    width: 100%;
    min-height: 75vh;
    filter: brightness(0.3);
}

.branding h1{
    position: relative;
    color:rgb(230, 164, 0);
    font-size: 60px;
    font-weight: bold;
    padding-top: 2em;
}

.branding button{
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    color: white;
    background: black;
    border: rgb(230, 164, 0) 1px solid;

    margin-top: 5rem;
    padding: 1rem;

    transition: background ease-in 0.3s;
}

.branding button:hover{
    background: rgb(230, 164, 0);
}

.spacing{
    margin-bottom: 5em;
    color: rgb(230, 164, 0);
}

.spacing h1{
    font-weight: bold;
}

.font-max{
    font-size: 70px;
    color: white;
}

.font-medium{
    font-size: 30px;
    color: white;
}

.intro{
    color: #e6a400;
    text-align: justify;
}

.increased-font{
    font-size: 20px;
}

.vid{
    position: relative;
}

.vid-text{
    position: absolute;
    top: -10%;
    left: -5%;

    background: rgb(230, 164, 0);
    color: white;

    padding-top: 2em;
    padding-bottom: 2em;
    padding-right: 1em;
    padding-left: 1em;

    font-size: 25px;
}

.vid-text span{
    font-size: 35px;
    font-weight: bold;
}

#galery{
    color: rgb(230, 164, 0);
}

#galery p{
    font-size: 20px;
}

.color-white{
    color: white;
    transition: color 0.5s ease;
    cursor: pointer;
}

.color-white:hover{
    color:rgb(238, 189, 65);
}

.footer{
    color:rgb(230, 164, 0);
}

.text-gold{
    color:rgb(230, 164, 0) !important;
}

.date-btn{
    width: 50px;
    height: 50px;

    border: none;

    background: rgb(230, 164, 0);
    color: white;
}

#confirm-icon{
    font-size: 200px;
}

.z-max{
    z-index: 10;
}

/*Loading*/
.lds-ring {
    display: inline-block;
    position: fixed;
    opacity: 0.5;
    width: 100%;
    height: 100%;
  }
.div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    top: 50%;
    left: 48%;
    transform: translate(-50%, 50%);
    width: 64px;
    height: 64px;
    border: 8px solid rgb(230, 164, 0);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: rgb(230, 164, 0) transparent transparent transparent;
}
.div:nth-child(1) {
    animation-delay: -0.15s;
}
.div:nth-child(2) {
    animation-delay: -0.25s;
}
.div:nth-child(3) {
    animation-delay: -0.35s;
}
@keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

.max-height{
    height:88vh;
    background-image: url('./images/banner.png');
    background-size: cover;
    background-position: center;
}

#login-panel{
    background: rgba(57, 57, 57, 0.616);
    backdrop-filter: blur( 11.5px );
    -webkit-backdrop-filter: blur( 11.5px );
    min-height: 50vh;
}

#login-panel form{
    z-index: 1;
}